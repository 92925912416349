<script>
import Layout from "@/router/layouts/franchise";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      Inventory: "Estoque",
      Orders: "Pedidos",
    },
    es: {
      Inventory: "Estoque",
      Orders: "Pedidos",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      orders: [],
    };
  },
  methods: {
    getOrders() {
      api
        .get("franchise/inventory/orders")
        .then((response) => {
          if (response.data.status == "success") {
            this.orders = response.data.list;
          }
        })
        .catch((error) => {
          if (error) {
            this.orders = [];
          }
        });
    },
  },
  mounted() {
    this.getOrders();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Estoque</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Pedidos</h3>
        <p></p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <vcl-list v-if="!orders" class="col-lg-6"></vcl-list>
          <template v-else-if="orders.length == 0">
            Nenhum registro encontrado.
          </template>
          <table v-else class="table table-nowrap">
            <thead class="thead-light">
              <tr>
                <th>Pedido</th>
                <th>Data</th>
                <th>Total</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(order, index) in orders" :key="index">
                <td class="align-middle">{{ order.id }}</td>
                <td class="align-middle">{{ order.date }}</td>
                <td class="align-middle">{{ order.total | currency }}</td>
                <td class="align-middle">{{ order.status }}</td>
                <td class="text-right">
                  <router-link :to="'/franchise/inventory/orders/' + order.id" class="btn btn-default btn-sm btn-rounded">
                    {{ t("Ver") }}
                    <i class="mdi mdi-arrow-right ml-1"></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>
